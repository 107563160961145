.App {
  background-image: linear-gradient(to right bottom, #47b8b6, #296098);
  width: 100%;
  height: 100%;
}

.Application {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.notes {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  color: #fff;
  font-size: 18px;
  font-weight: 900;
  line-height: 1.5em;
}
.status {
  display: flex;
  flex: 1;
  align-items: end;
  justify-content: center;
  color: #fff;
  font-size: 18px;
  font-weight: 900;
}

.animate {
  flex: 1;
}

@keyframes animateHeart {
  // scale down and scale up faster in irregular intervals to get the throbbing effect
  0% {
    transform: scale(0.9);
  }
  10% {
    transform: scale(0.95);
  }
  20% {
    transform: scale(0.9);
  }
  30% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(0.9);
  }
}
.button {
  &.copied {
    opacity: 0.5;
  }
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.3);
  transform: scale(1);
  cursor: pointer;
  margin: auto;
  width: 120px;
  text-align: center;
  text-decoration: none;
  color: #55c4c4;
  font-weight: 900;
  font-size: 18px;
  background-color: #fff;
  border-radius: 5px;
  height: 60px;
  margin-top: 20px;
  transition: all 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 5px;
  &.animate {
    animation: pulse 2s infinite;
  }
}

.loading {
  position: relative;
  overflow: hidden;
  height: 150px;
}

.loader {
  transform: transale3d(0, 0, 0);
  animation: loader 10s linear infinite;
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
}
@keyframes loader {
  0% {
    left: -50px;
  }
  100% {
    left: calc(100% + 50px);
  }
}
.box {
  width: 50px;
  height: 50px;
  background: #fff;
  animation: animate 0.5s linear infinite;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 3px;
}
@keyframes animate {
  17% {
    border-bottom-right-radius: 3px;
  }
  25% {
    transform: translateY(15px) rotate(22.5deg);
  }
  50% {
    transform: translateY(30px) scale(1, 0.9) rotate(45deg);
    border-bottom-right-radius: 40px;
  }
  75% {
    transform: translateY(15px) rotate(67.5deg);
  }
  100% {
    transform: translateY(0) rotate(90deg);
  }
}
.shadow {
  width: 50px;
  height: 5px;
  background: #000;
  opacity: 0.3;
  position: absolute;
  top: 70px;
  left: 0;
  border-radius: 50%;
  animation: shadow 0.5s linear infinite;
}
@keyframes shadow {
  50% {
    transform: scale(1.2, 1);
  }
}
@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
@keyframes flip {
  from {
    -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0)
      rotate3d(0, 1, 0, -360deg);
    transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0)
      rotate3d(0, 1, 0, -360deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  // 40% {
  //   -webkit-transform: perspective(400px) scale3d(1, 1, 1)
  //     translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
  //   transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px)
  //     rotate3d(0, 1, 0, -190deg);
  //   -webkit-animation-timing-function: ease-out;
  //   animation-timing-function: ease-out;
  // }

  50% {
    -webkit-transform: perspective(400px) scale3d(1, 1, 1)
      translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px)
      rotate3d(0, 1, 0, -170deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  // 80% {
  //   -webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95)
  //     translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
  //   transform: perspective(400px) scale3d(0.95, 0.95, 0.95) translate3d(0, 0, 0)
  //     rotate3d(0, 1, 0, 0deg);
  //   -webkit-animation-timing-function: ease-in;
  //   animation-timing-function: ease-in;
  // }

  to {
    -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0)
      rotate3d(0, 1, 0, 0deg);
    transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0)
      rotate3d(0, 1, 0, 0deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
}
.flip {
  animation: flip 3s linear infinite;
}

.broken {
  transform: translateY(10px) rotate(15deg);
}
